import { useToggle } from 'usehooks-ts'

import {
  type SubscriptionPortalResponse,
  type SubscriptionPriceModel,
} from '@fv/models'

import { shipperFetch } from '../../../utils/shipperFetch'
import { useSubscriptionContextQuery } from './queries'

export const useSubscriptionContext = () => {
  const query = useSubscriptionContextQuery()
  return query.data
}
export const useRedirectToStripePortal = () => {
  const [loading, toggleLoading] = useToggle()
  const redirect = async () => {
    toggleLoading()
    const response =
      await shipperFetch<SubscriptionPortalResponse>(`/subscription/portal`)
    window.location.href = response.redirect
  }
  return {
    loading,
    redirect,
  }
}

export const getPricingString = (pricing: SubscriptionPriceModel) =>
  `$${pricing.amount / 100}/${pricing.intervalCount > 1 ? `${pricing.interval} ` : ''}${pricing.interval}`
