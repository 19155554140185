import dayjs from 'dayjs'

import { CredentialDetail } from '../models/account-carrier'

export function needsShipperAttention({
  code,
  details,
  isBeta,
}: {
  code?: string
  details?: CredentialDetail[]
  isBeta?: boolean
}) {
  if (code === 'upgf' || code === 'tfin') {
    return !details?.some(
      d =>
        d.type === 'refresh-token' &&
        d.value &&
        dayjs.utc(d.expires).isAfter(dayjs.utc()),
    )
  }

  if (code === 'upss') {
    const hasIdAndSecret =
      details?.some(d => d.type === 'client-id' && d.value) &&
      details?.some(d => d.type === 'client-secret' && d.value)
    return !hasIdAndSecret
  }

  if (code === 'fxfe' && isBeta) {
    const hasKeyAndSecret =
      details?.some(d => d.type === 'child-key' && d.value) &&
      details?.some(d => d.type === 'child-secret' && d.value)
    return !hasKeyAndSecret
  }

  return false
}
