import { ObjectId } from '@freightview/object-id'
import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { FvButton, type FvButtonProps } from '@fv/client-components'
import { submitForm, useFormModel } from '@fv/client-core'

import {
  InputGroup,
  InputGroupWrapper,
} from '../../../components/inputs/InputGroup'
import { FreightClassEstimate } from '../FreightClassEstimate'
import { ContainsForm } from './ContainsForm'
import { ContainsRow } from './ContainsRow'
import {
  DescriptionField,
  DimensionsField,
  FreightClassField,
  HandlingUnitTypeField,
  IsHazardousField,
  NmfcField,
  PackagingField,
  ProductCatalogField,
  QuantityField,
  SecondaryPackageTypeField,
  StackableField,
  WeightField,
} from './Fields'
import { HazmatForm } from './HazmatForm'
import { defaultLoadContainsItem } from './loadItemHelpers'
import { useItemActions, useItemsState } from './loadItemsContext'
import {
  type FormLayoutProps,
  type ItemChangeFn,
  type LoadItemFormModel,
  LoadItemSchema,
} from './types'

export const LTLItemForm = (props: FormLayoutProps) => {
  const { activateContains, setEditingContains } = useItemActions()
  const { step, disabled, itemForm } = useItemsState()
  const { register, onChange, value } = props
  const { register: simpleContainsRegister } = useFormModel({
    value: value.contains?.[0] ?? defaultLoadContainsItem(),
    schema: LoadItemSchema,
    onChange: v => {
      let contains: LoadItemFormModel[] | undefined = [v]
      if (!v.quantity && !v.type) {
        contains = undefined
      }
      onChange({ contains })
    },
  })
  const handleContainsOpen = () => {
    if (itemForm && !itemForm?.checkValidity()) {
      submitForm(itemForm)
      return
    } else {
      itemForm?.reset()
    }
    setEditingContains(value)
  }
  const simpleContainsQuantity = simpleContainsRegister('quantity')
  const id = value._id ?? new ObjectId().toHexString()
  const isQuoting = step === 'quoting'
  const isBooking = step === 'booking'
  const isMultiClass = value.handlingUnitType === 'multi'
  const containsLength = value.contains?.length ?? 0

  return (
    <>
      <HandlingUnitTypeField
        register={register}
        onChange={onChange}
        value={value}
      />
      <ProductCatalogField
        className={clsx({
          'basis-full': isQuoting,
          'b1450:basis-full basis-5/12': isBooking,
        })}
        required={isBooking}
        doublesAsDescription={isBooking}
        onChange={onChange}
        value={value}
        disabled={disabled}
        label={isBooking ? 'Description' : 'Search'}
      />
      {isQuoting && (
        <DescriptionField register={register} className="basis-full" />
      )}
      <PackagingField
        className={clsx({
          'b1600:basis-1/2 basis-3/5': !isMultiClass && isQuoting,
          'basis-1/3': isMultiClass && isQuoting,
          'b1450:basis-3/12 basis-2/12': isBooking,
        })}
        register={register}
        value={value}
        onChange={onChange}
      />
      <StackableField
        className={clsx({
          'b1600:basis-1/2 basis-2/5': isQuoting && !isMultiClass,
          'basis-1/3': isQuoting && isMultiClass,
          'basis-2/12': isBooking,
        })}
        register={register}
      />
      {isBooking && !isMultiClass && (
        <>
          <InputGroup
            className="b1450:flex-1 basis-1/12"
            inputProps={simpleContainsQuantity}
            inputType="amount"
            label="Contains"
            required={simpleContainsQuantity.required}
            locked={value?.readOnly?.saidToContain}
          />
          <SecondaryPackageTypeField
            className="basis-2/12"
            register={simpleContainsRegister}
            parentPackageType={value.type}
            label="Units"
          />
        </>
      )}
      {!isMultiClass && (
        <>
          <QuantityField
            className={clsx({
              'b1600:basis-1/2 basis-1/5': isQuoting,
              'basis-1/12': isBooking,
            })}
            register={register}
          />
          <WeightField
            className={clsx({
              'b1600:basis-1/2 basis-2/5': isQuoting,
              'basis-2/12': isBooking,
            })}
            register={register}
            label="Total weight"
          />
        </>
      )}

      <DimensionsField
        register={register}
        className={clsx(`form-group form-group--collection`, {
          'b1600:basis-1/2 basis-2/5': isQuoting && !isMultiClass,
          'basis-1/3': isMultiClass,
          'basis-3/12': isBooking,
        })}
      />
      {!isMultiClass && (
        <>
          <NmfcField
            className={clsx({
              'b1450:flex-1 basis-2/12': isBooking,
              'b1600:basis-1/2 basis-1/3': isQuoting,
            })}
            register={register}
          />
          <FreightClassField
            className={clsx({
              'b1600:basis-1/2 basis-1/3': isQuoting,
              'basis-2/12': isBooking,
            })}
            register={register}
          />
          <IsHazardousField
            type="radio"
            register={register}
            className={clsx({
              'b1600:basis-1/2 basis-1/3': isQuoting,
              'basis-2/12': isBooking,
            })}
          />
        </>
      )}
      {value.isHazardous && isBooking && !isMultiClass && (
        <div className="form-row m-1 grid basis-full grid-cols-6 border border-[#ff6501] bg-white px-4 pb-2 pt-4">
          <HazmatForm
            value={value.hazardous}
            readOnly={value.readOnly}
            itemId={id}
            onChange={hazardous => onChange({ ...value, hazardous })}
            disabled={disabled}
          />
        </div>
      )}

      {isQuoting && !isMultiClass && (
        <div className="mb-1 basis-full px-1">
          <FreightClassEstimate {...value}>
            <div className="border-fv-border mx-1 mt-2 basis-full border-b " />
          </FreightClassEstimate>
        </div>
      )}

      {isMultiClass && (
        <div className="basis-full">
          <div
            className={clsx('-mx-3', {
              'bg-fv-blue-100 border-t-fv-blue-300 border-b border-t border-dashed border-b-gray-300':
                isQuoting,
            })}
          >
            {isQuoting && containsLength <= 2 && (
              <InlineContainsEditor {...props} />
            )}
            {(isBooking || containsLength > 2) && (
              <div className="flex flex-col">
                {value.contains?.map((c, ix) => (
                  <ContainsRow
                    key={`contains-${ix}`}
                    onEditClick={() => {
                      setEditingContains(value)
                      activateContains(ix)
                    }}
                    value={c}
                    index={ix + 1}
                  />
                ))}
              </div>
            )}

            <InputGroupWrapper name="contains" className="my-4 pl-3">
              <ManageContainsButton onClick={handleContainsOpen}>
                Add/edit commodities on this handling unit
              </ManageContainsButton>
            </InputGroupWrapper>
          </div>
        </div>
      )}
    </>
  )
}

const InlineContainsEditor = ({ value, onChange }: FormLayoutProps) => {
  const handleContainsChange =
    (index: number): ItemChangeFn =>
    v => {
      onChange({
        ...value,
        contains: value.contains?.map((c, ix) =>
          ix === index
            ? {
                ...c,
                ...v,
              }
            : c,
        ),
      })
    }
  return (
    <div>
      {value.contains?.slice(0, 2).map((item, index) => (
        <div
          className="border-fv-blue-400 odd:bg-fv-blue-50  border-b border-dashed px-4 pt-2"
          key={item?._id ?? index}
        >
          <ContainsForm
            value={item ?? { _id: '' }}
            onChange={handleContainsChange(index)}
          />
        </div>
      ))}
    </div>
  )
}

export const ManageContainsButton = ({
  onClick,
  children,
}: PropsWithChildren<Pick<FvButtonProps, 'onClick'>>) => (
  <FvButton
    className="flex items-center justify-start rounded-full"
    icon="pen-alt"
    onClick={onClick}
  >
    {children}
  </FvButton>
)
