import dayjs from 'dayjs'
import pluralize from 'pluralize'

import InfoBox from '../../../components/InfoBox'
import { useSubscriptionContext } from './hooks'

export const SubscriptionStatusAlert = () => {
  const context = useSubscriptionContext()
  const isPastDue = context.status === 'past_due'
  const isUnpaid = context.status === 'unpaid'
  const isCanceled = context.cancelAtEnd

  if (isCanceled) {
    const daysLeft = dayjs.utc(context.ends).diff(dayjs.utc(), 'day')
    return (
      <InfoBox className="mb-6">
        Your subscription has been canceled and you will lose access in{' '}
        {daysLeft} {pluralize('day', daysLeft)} days.
      </InfoBox>
    )
  }

  if (isPastDue) {
    return (
      <InfoBox className="mb-6">
        Your payment is past due and you will lose access soon.
      </InfoBox>
    )
  }
  if (isUnpaid) {
    return (
      <InfoBox className="mb-6">Your subscription has been suspended.</InfoBox>
    )
  }
  return null
}
