import { type PropsWithChildren } from 'react'

import { FvButton, FvLinkButton } from '@fv/client-components'

import { SettingsPageLayout } from '../SettingsPageLayout'
import { SettingsSection } from '../SettingsSection'
import { useRedirectToStripePortal } from './hooks'
import {
  useSetupEasySubscription,
  useSetupTransferSubscription,
} from './mutations'

export const SubscriptionsSetupPage = () => {
  const portalRedirect = useRedirectToStripePortal()
  const setupTransferAccount = useSetupTransferSubscription()
  const setupEasyAccount = useSetupEasySubscription()

  const handleSetupClck = async () => {
    await setupTransferAccount.mutateAsync()
    window.location.reload()
  }

  const handleEasyClick = async () => {
    await setupEasyAccount.mutateAsync()
    await portalRedirect.redirect()
  }

  return (
    <SettingsPageLayout>
      <SettingsSection title="Setup your subscription">
        <p>
          Freightview uses{' '}
          <FvLinkButton
            href="https://stripe.com"
            target="_blank"
            theme="underlined"
          >
            Stripe
          </FvLinkButton>{' '}
          to help simplify payments for our customers. You can set your
          subscription to auto-renew via credit card or ACH debit. Another
          option available is to pay your subscription via ACH credit transfer.
        </p>
        <div className="grid grid-cols-2 pt-2 gap-x-4 -mt-4">
          <SubOption>
            <p>
              You can pay your subscription using a credit or bank account (ACH
              debit). Setup your preferred payment method and your subscription
              will automatically renew each billing cycle.
            </p>
            <FvButton
              loading={setupEasyAccount.isLoading || portalRedirect.loading}
              onClick={handleEasyClick}
              theme="primary"
            >
              Get started
            </FvButton>
          </SubOption>
          <SubOption>
            <p>
              Select this option to view bank account information into which you
              can submit your subscription payments. You'll then be responsible
              for transferring funds to that account prior to or on your
              designated renewal date.
            </p>
            <FvButton onClick={handleSetupClck} theme="primary">
              Setup ACH credit transfer
            </FvButton>
          </SubOption>
        </div>
      </SettingsSection>
    </SettingsPageLayout>
  )
}

const SubOption = ({ children }: PropsWithChildren) => {
  return (
    <div className="border p-3 bg-fv-blue-50 and border-fv-blue-200 flex flex-col justify-between">
      {children}
    </div>
  )
}
