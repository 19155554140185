import { useEffect, useState } from 'react'

import { FvButton, Icon } from '@fv/client-components'
import { needsShipperAttention } from '@fv/models/core'

import InfoBox from '../../../components/InfoBox'
import { shipperFetch } from '../../../utils/shipperFetch'
import {
  useAccountCarrierFromState,
  useIsBeta,
} from '../AccountCarrierFormProvider'
import { type CredentialFormProps } from '../types'

type Props = CredentialFormProps
export const TForceProvisioning = ({ value, onChange }: Props) => {
  const beta = useIsBeta()
  const [isWaiting, setWaiting] = useState(false)
  const accountCarrier = useAccountCarrierFromState()

  const isConnected = !needsShipperAttention({
    code: 'upgf',
    details: accountCarrier?.details ?? value,
    isBeta: beta,
  })

  const needsConnection = !isWaiting && !isConnected

  useEffect(() => {
    if (isWaiting && isConnected) setWaiting(false)
  }, [isWaiting, isConnected])

  useEffect(() => {
    // make sure our form model stays in sync with server model since creds can't be changed via UI for TForce
    onChange(accountCarrier?.details ?? [])
  }, [accountCarrier, onChange])

  const handleConnectClick = async () => {
    setWaiting(true)
    const { redirect } = await shipperFetch<{ redirect: string }>(
      `/provision/mfa/auth?code=tfin`,
    )
    window.open(redirect)
  }

  return (
    <div className="mb-4 border-b">
      <InfoBox>
        To begin using TForce Freight with Freightview, simply connect your
        TForce Freight account to your Freightview account by clicking 'Connect
        my account' and opening a popup window to TForce Freight.
      </InfoBox>
      <div className="mb-4 flex items-center gap-x-2">
        <div>TForce Freight connection status:</div>
        {isConnected && (
          <div>
            <Icon icon="check" />
            <span>Connected</span>
          </div>
        )}
        {isWaiting && (
          <div>
            <Icon icon="spinner" />
            <span>Connecting</span>
          </div>
        )}
        {needsConnection && (
          <>
            <div>
              <Icon icon="exclamation-triangle" className="text-red-400" />
              <span>Not connected</span>
            </div>
            <div className="h-px border-fv-gray border-t flex-1 border-dotted" />
            <div>
              <FvButton onClick={handleConnectClick} theme="primary">
                Connect my account
              </FvButton>
            </div>
          </>
        )}
      </div>
      {isWaiting && (
        <p className="text-sm italic">
          Once you've completed the steps on TForce Freight's website, your
          account will automatically be connected.
        </p>
      )}
    </div>
  )
}
