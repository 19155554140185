import dayjs from 'dayjs'
import capitalize from 'lodash/capitalize'

import { FvLinkButton, Tooltip } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { List, ListItem, ListItemActions } from '../../components/List'
import {
  type LabeledDocument,
  useLabeledDocuments,
} from '../../hooks/documents'
import { composeFvBolUrl } from '../../utils/shipmentFuncs'
import DeleteDocumentButton from './DeleteDocumentButton'

type DocumentListProps = Pick<FullShipment, 'documents'> & {
  className?: string
  loadId?: string
  canDelete?: boolean
  onClick?: (doc: LabeledDocument) => void
}

const DocumentList = ({
  documents,
  className,
  loadId,
  onClick,
  canDelete = false,
}: DocumentListProps) => {
  const fvBolUrl = composeFvBolUrl({ documents })
  const docs = useLabeledDocuments(documents)

  return (
    <List className={className}>
      {docs.map((doc, index) => (
        <ListItem
          custom
          key={doc._id ?? index}
          {...(doc.source === 'shipper' &&
            canDelete &&
            loadId && {
              actions: (
                <ListItemActions>
                  <DeleteDocumentButton documentId={doc._id} loadId={loadId} />
                </ListItemActions>
              ),
            })}
        >
          <div className="flex-1 overflow-hidden">
            <Tooltip label={generateTooltipLabel(doc)}>
              <span>
                <FvLinkButton
                  icon="file"
                  className="p-[.6rem] pl-0 w-full text-left block truncate"
                  iconClass="fa-fw"
                  {...(!!onClick && { onClick: () => onClick(doc) })}
                  {...(!onClick && {
                    href: doc.label === 'Freightview BOL' ? fvBolUrl : doc.url,
                    rel: 'noreferrer',
                    target: '_blank',
                  })}
                >
                  {doc.label}
                </FvLinkButton>
              </span>
            </Tooltip>
          </div>
        </ListItem>
      ))}

      {!documents?.length && (
        <InfoBox>No documents have been uploaded.</InfoBox>
      )}
    </List>
  )
}

const added = 'added'
const generateTooltipLabel = (doc: LabeledDocument) => {
  if (!doc.uploadDate) return ''

  const source = doc.source === 'carrier' ? capitalize(doc.source) : ''
  const verb = !source ? capitalize(added) : added
  return `${source} ${verb} ${dayjs
    .utc(doc.uploadDate)
    .local()
    .format('YYYY-MM-DD')}`
}
export default DocumentList
