import sumBy from 'lodash/sumBy'

import { coreApiRootUrl, getToken } from '@fv/client-core'
import { type ListShipment, type ShippingLabelSize } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useUserSettings } from '../../hooks/settings'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { labelOptions } from './labelConfig'

const defaultLabelCount = 1
const defaultLabelSize: ShippingLabelSize = '8.5x11'

type LabelSettings = {
  labelCount: number
  labelSize: ShippingLabelSize
  useLabelSettings: boolean
}

export function useLabelSettings(
  load: Pick<ListShipment, 'items' | 'workflow'>,
): LabelSettings {
  const { items, workflow } = load
  const settingsQuery = useUserSettings(!!getToken())
  const labelSettings = {
    labelCount: defaultLabelCount,
    labelSize: defaultLabelSize,
    useLabelSettings: true,
  }

  if (workflow === 'parcel') return labelSettings

  const settings = settingsQuery.data?.settings.shipping
  const ltlLabelCount = settings?.ltl?.preferredLabelCount ?? defaultLabelCount
  const ltlLabelSize = settings?.ltl?.preferredLabelSize ?? defaultLabelSize
  const itemQuantity = sumBy(items, 'quantity') || 1

  if (workflow === 'truckload') {
    const labelsPerItem = settings?.tl?.preferredLabelCount ?? ltlLabelCount

    labelSettings.labelCount = Math.round(labelsPerItem * itemQuantity)
    labelSettings.labelSize = settings?.tl?.preferredLabelSize ?? ltlLabelSize
    labelSettings.useLabelSettings = !!settings?.tl?.rememberLabelSize
  } else {
    labelSettings.labelCount = Math.round(ltlLabelCount * itemQuantity)
    labelSettings.labelSize = ltlLabelSize
    labelSettings.useLabelSettings = !!settings?.ltl?.rememberLabelSize
  }

  if (labelSettings.useLabelSettings) {
    const labelConfig = labelOptions.find(
      o => o.size === labelSettings.labelSize,
    )

    // `labelCount` of 0 means "prompt me", sheets require start position
    if (!labelSettings.labelCount || labelConfig?.type === 'sheet') {
      labelSettings.useLabelSettings = false
    }
  }

  return labelSettings
}

type ShipmentLabels = {
  hasLabels: boolean
  href?: string
  isLoading: boolean
}

export function useShipmentLabels(
  load: Pick<
    ListShipment,
    | 'documents'
    | 'items'
    | 'loadId'
    | 'status'
    | 'workflow'
    | 'bol'
    | 'selectedQuote'
  >,
): ShipmentLabels {
  const { loadId, status, workflow, bol } = load
  const settings = useLabelSettings(load)
  const carrierSettings = useCarrierPublicSettings(
    load.selectedQuote?.carrierId,
  )

  const isPending =
    carrierSettings.isFetching ||
    (!carrierSettings.carrierPublicSettings?.ltlLabelsRequireBOL &&
      bol.status !== 'ok' &&
      workflow === 'ltl')

  const labels: ShipmentLabels = {
    hasLabels: status !== 'canceled' && status !== 'pending' && !isPending,
    isLoading: false,
  }

  if (workflow === 'parcel') {
    const url = getParcelLabelUrl(load)
    labels.href = url
    labels.isLoading = !url
  } else if (settings.useLabelSettings) {
    labels.href = composeLabelsUrl({
      count: settings.labelCount,
      loadId,
      size: settings.labelSize,
    })
  }

  return labels
}

export function getParcelLabelUrl({
  documents,
}: Pick<ListShipment, 'documents'>) {
  const labelDoc = documents?.find(d => d.type === 'label-combined')
  return labelDoc?.url
}

export function composeLabelsUrl({
  count,
  loadId,
  size,
  start = 0,
  isPublic,
}: {
  count: number
  loadId: string
  size: ShippingLabelSize
  start?: number
  isPublic?: boolean
}) {
  const params = new URLSearchParams()
  const prefix = isPublic
    ? `${coreApiRootUrl}/public-documents`
    : `${apiUri}/documents`

  params.set('count', count.toString())
  params.set('size', size)
  params.set('start', start.toString())

  return `${prefix}/${loadId}/label-pdf?${params.toString()}`
}
