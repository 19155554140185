import { useNumberFormatter } from '@fv/client-core'

import { PropsWithClassName } from '../../../client-types/src/lib/PropsWithClassName'

type Props = PropsWithClassName<{
  val: number | string | undefined | null
  currency?: boolean
  includeEmptyDecimals?: boolean
  precision?: number
}>
export const FvNum = ({
  val,
  currency,
  includeEmptyDecimals,
  className,
  precision = 2,
}: Props) => {
  const formatter = useNumberFormatter({
    currency,
    includeEmptyDecimals,
    precision,
  })
  if (val === undefined || val === null) return ''
  return <span className={className}>{formatter(val)}</span>
}
