import dayjs from 'dayjs'
import { type Dispatch, type SetStateAction, useMemo } from 'react'

import { FvButton, FvLinkButton } from '@fv/client-components'
import { useFormModelState } from '@fv/client-core'
import { type DTO } from '@fv/models/core'

import { ValidatedForm } from '../../components/inputs'
import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { AuditPriceDisplay } from './components/AuditPriceDisplay'
import { AuditRateBreakdown } from './components/AuditRateBreakdown'
import { AuditCardArrowCtn } from './AuditCardArrowCtn'
import AuditNotes from './AuditNotes'
import { DatePickerInput } from './DatePickerInput'
import { useUpdateInvoice } from './mutations'
import { useResetAudit } from './queries'
import { type Audit } from './types'

type AcceptedAuditFormProps = {
  audit: DTO<Audit>
  isActive: boolean
  setActive: Dispatch<SetStateAction<string | null>>
}

export const AcceptedAuditForm = ({
  audit,
  isActive,
  setActive,
}: AcceptedAuditFormProps) => {
  const { invoiceNumber, invoiceDate, loadId, notes, payments } = audit
  const { resetAudit, isResettingAudit } = useResetAudit(audit, 'accepted')
  const updateInvoice = useUpdateInvoice()
  const { register, value, setValue } = useFormModelState({
    initialValue: {
      invoiceNumber,
      invoiceDate,
    },
  })

  const pendingPaymentAmount = useMemo(() => {
    if (!payments?.length) return 0

    return payments.reduce((total, p) => {
      if (!p.remitted) total += p.amount
      return total
    }, 0)
  }, [payments])

  const showSaveButton = useMemo(() => {
    return (
      invoiceNumber !== value.invoiceNumber ||
      (invoiceDate
        ? !dayjs(invoiceDate).isSame(dayjs(value.invoiceDate), 'date')
        : !!value.invoiceDate)
    )
  }, [invoiceNumber, invoiceDate, value])

  const handleSubmit = async () => {
    await updateInvoice.mutateAsync({
      loadId,
      invoiceDate: value.invoiceDate,
      invoiceNumber: value.invoiceNumber,
    })
  }

  return (
    <>
      <div className="flex items-center gap-2">
        <AuditPriceDisplay amount={pendingPaymentAmount} />
        <ValidatedForm
          onValidSubmit={handleSubmit}
          className="flex items-center gap-2 w-auto"
        >
          <InputGroup
            className="mb-0"
            inputType="text"
            inputProps={{
              placeholder: 'Invoice number',
              ...register('invoiceNumber'),
            }}
            startContent={<InputAdornment position="start" icon="hashtag" />}
          />
          <DatePickerInput
            className="mb-0 w-52"
            canClear={false}
            invoiceDate={value.invoiceDate}
            isBusy={updateInvoice.isLoading}
            setInvoiceDate={invoiceDate =>
              setValue(p => ({ ...p, invoiceDate }))
            }
          />
          {showSaveButton && (
            <FvButton
              icon="check"
              type="submit"
              loading={updateInvoice.isLoading}
              iconClass="text-fv-green-100"
              theme="default"
            >
              Save
            </FvButton>
          )}
        </ValidatedForm>
        <div className="divided-content divided-content--end divided-content--start ml-2">
          <FvLinkButton
            className=""
            onClick={() => {
              !isResettingAudit && resetAudit(loadId)
            }}
            icon={isResettingAudit ? 'spinner' : 'file-invoice-dollar'}
          >
            <span className="b1400:hidden">Move back to outstanding</span>
            <span className="hidden b1400:inline-block">
              Move to outstanding
            </span>
          </FvLinkButton>
          <FvLinkButton
            title="Details"
            onClick={() => {
              setActive(prev => (prev === loadId ? null : loadId))
            }}
            icon={`caret-circle-${isActive ? 'up' : 'down'}`}
          >
            <span className="b1350:hidden">Details</span>
          </FvLinkButton>
        </div>
      </div>
      {isActive && (
        <AuditCardArrowCtn>
          <AuditRateBreakdown audit={audit} />
        </AuditCardArrowCtn>
      )}

      {isActive && <AuditNotes className="mt-4" notes={notes} />}
    </>
  )
}
