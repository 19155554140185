import { ObjectId } from '@freightview/object-id'
import defaults from 'lodash/defaults'
import isNil from 'lodash/isNil'
import padStart from 'lodash/padStart'
import { type ChangeEvent } from 'react'

import {
  type DimensionsUOM,
  type HazardousDetail,
  type LegacyHandlingUnitDTO,
  type LegacyHazardDetail,
  type LegacyReadOnlyDetail,
  type LoadHandlingUnit,
  type PackagingType,
  type ProductCatalogEntry,
  type ProductMode,
  type Workflow,
} from '@fv/client-types'

import { type FormCommodity } from './FormCommodity'

const palletDimMap: Partial<
  Record<PackagingType, { width: number; length: number }>
> = {
  'pallet-48x48': {
    width: 48,
    length: 48,
  },
  'pallet-48x40': {
    width: 40,
    length: 48,
  },
  'pallet-60x40': {
    width: 40,
    length: 60,
  },
}

export const normalizePackageType = (type: PackagingType): PackagingType => {
  if (Object.keys(palletDimMap).some(k => k === type)) {
    return 'pallet'
  }
  return type
}

export const dimsFromPackageType = (
  type: PackagingType,
): { length?: string; width?: string } => {
  const palletMatch = palletDimMap[type]
  if (palletMatch) {
    return {
      width: palletMatch.width.toString(),
      length: palletMatch.length.toString(),
    }
  } else if (type === 'pallet') {
    return {
      length: '',
      width: '',
    }
  }
  return {}
}

export const packageTypeFromDims = (
  type: PackagingType | '',
  workflow: Workflow,
  dims: {
    length?: number
    width?: number
  },
): PackagingType | '' => {
  if (!/pallet/.test(type)) {
    return type
  }

  if (workflow === 'truckload') {
    return 'pallet'
  }

  const match = Object.entries(palletDimMap).find(
    ([k, v]) => v.length === dims.length && v.width === dims.width,
  )?.[0] as PackagingType

  return match ?? 'pallet'
}

export function buildHandlingUnitDTO(item: FormCommodity): LoadHandlingUnit {
  const [nmfcNumber, nmfcSubNumber] = item.nmfc.split('-')
  const { packingGroup, primaryClass, secondaryClass, tertiaryClass } = item
  const hazmatId = item.hazmatId?.trim()
  const permitNumber = item.permitNumber?.trim()

  return {
    _id: item.id,
    description: item.description.trim(),
    dimensionsUOM: item.dimensionsUOM,
    dropLocationSequence: item.dropSequence,
    pickupLocationSequence: item.pickSequence,
    quantity: Number(item.quantity),
    // Reset custom pallet sizes back to default
    type: normalizePackageType(item.type),
    weight: Number(item.weight),
    weightUOM: item.weightUOM,
    ...(item.declaredValue && {
      declaredValue: Number(item.declaredValue),
      declaredValueCurrency: item.declaredValueCurrency,
    }),
    ...(item.hazardous === 'Yes' && {
      hazardous: [
        {
          ...(hazmatId && { hazmatId }),
          ...(packingGroup && { packingGroup }),
          ...(permitNumber && { permitNumber }),
          ...(primaryClass && { primaryClass }),
          ...(secondaryClass && { secondaryClass }),
          ...(tertiaryClass && { tertiaryClass }),
        },
      ],
    }),
    ...(item.height && { height: Number(item.height) }),
    ...(item.length && { length: Number(item.length) }),
    ...(item.width && { width: Number(item.width) }),

    // LTL-specific fields
    ...(item.freightClass && { freightClass: Number(item.freightClass) }),
    ...(item.handlingUnitId && { handlingUnitId: item.handlingUnitId }),
    ...(item.saidToContain && {
      contains: [
        {
          _id: new ObjectId().toHexString(),
          description: '',
          quantity: Number(item.saidToContain),
          type: item.saidToContainPackagingType || 'unit',
        },
      ],
    }),
    ...(item.stackable === 'Yes' && { stackable: true }),
    ...(item.nonStandard === 'Yes' && { nonStandard: true }),
    ...(nmfcNumber && { nmfcNumber: Number(nmfcNumber) }),
    ...(nmfcSubNumber && { nmfcSubNumber: Number(nmfcSubNumber) }),
  }
}

export function buildHazardousDetails(
  item?: Partial<LoadHandlingUnit>,
): HazardousDetail {
  const overrides = item?.hazardous?.[0]

  return {
    hazmatId: undefined,
    packingGroup: undefined,
    permitNumber: undefined,
    primaryClass: undefined,
    secondaryClass: undefined,
    tertiaryClass: undefined,
    ...overrides,
  }
}

export function buildFormCommodity({
  dropSequence = 1,
  item,
  loadId,
  pickSequence = 0,
}: {
  dropSequence?: number
  item?: Partial<LoadHandlingUnit>
  loadId: string
  pickSequence?: number
}): FormCommodity {
  return {
    declaredValue: String(item?.declaredValue || ''),
    declaredValueCurrency: item?.declaredValueCurrency || 'usd',
    description: item?.description || '',
    dimensionsUOM: 'in',
    dropSequence,
    freightClass: String(item?.freightClass || ''),
    handlingUnitId: item?.handlingUnitId || '',
    hazardous: Array.isArray(item?.hazardous) ? 'Yes' : 'No',
    height: String(item?.height || ''),
    id: item?._id ?? new ObjectId().toHexString(),
    length: String(item?.length || ''),
    loadId,
    nmfc: displayNmfc(item),
    nonStandard: item?.nonStandard ? 'Yes' : 'No',
    pickSequence,
    quantity: String(item?.quantity || ''),
    saidToContain: String(item?.contains?.[0]?.quantity || ''),
    saidToContainPackagingType: item?.contains?.[0]?.type || '',
    stackable: item?.stackable ? 'Yes' : 'No',
    type: item?.type || 'pallet',
    weight: String(item?.weight || ''),
    weightUOM: item?.weightUOM || 'lbs',
    width: String(item?.width || ''),
    ...buildHazardousDetails(item),
  }
}

export function buildLegacyHazardDetail(
  values?: Partial<LegacyHazardDetail>,
): LegacyHazardDetail {
  return {
    hazardClass: values?.hazardClass ?? '',
    hazmatId: values?.hazmatId ?? '',
    packingGroup: values?.packingGroup ?? '',
    secondaryHazardClass: values?.secondaryHazardClass ?? '',
    specialPermit: values?.specialPermit ?? '',
    tertiaryHazardClass: values?.tertiaryHazardClass ?? '',
  }
}

const defaultReadOnly: LegacyReadOnlyDetail = {
  declaredValue: false,
  description: false,
  freightClass: false,
  hazardClass: false,
  hazardous: false,
  hazmatId: false,
  height: false,
  length: false,
  nmfc: false,
  nonStandard: false,
  package: false,
  packingGroup: false,
  pieces: false,
  saidToContain: false,
  saidToContainPackagingType: false,
  secondaryHazardClass: false,
  specialPermit: false,
  stackable: false,
  tertiaryHazardClass: false,
  weight: false,
  width: false,
}

export function buildProductCatalogEntry(
  mode: ProductMode,
  unit?: LegacyHandlingUnitDTO,
): ProductCatalogEntry {
  return {
    _id: unit?._id ?? '',
    autoGenerated: unit?.autoGenerated ?? false,
    declaredValue: String(unit?.declaredValue?.amount ?? ''),
    description: unit?.description ?? '',
    freightClass: String(unit?.freightClass ?? ''),
    hazardous: unit?.hazardous ? 'Yes' : 'No',
    height: String(unit?.height ?? ''),
    length: String(unit?.length ?? ''),
    mode: unit?.mode ?? mode,
    nmfc: unit?.nmfc ?? '',
    nonStandard: unit?.nonStandard ? 'Yes' : 'No',
    package:
      unit?.package === undefined
        ? mode === 'parcel'
          ? 'custom'
          : 'pallet'
        : (unit.package ?? ''),
    pieces: String(unit?.pieces ?? ''),
    readOnly: defaults(unit?.readOnly ?? defaultReadOnly, defaultReadOnly),
    saidToContain: String(unit?.saidToContain ?? ''),
    saidToContainPackagingType: unit?.saidToContainPackagingType ?? '',
    stackable: unit?.stackable ? 'Yes' : 'No',
    weight: String(unit?.weight ?? ''),
    width: String(unit?.width ?? ''),
    ...buildLegacyHazardDetail(unit?.hazard),
  }
}

export function buildLegacyHandlingUnitDTO(
  product: ProductCatalogEntry,
): LegacyHandlingUnitDTO {
  const {
    declaredValue,
    freightClass,
    hazardClass,
    hazmatId,
    nmfc,
    packingGroup,
    saidToContainPackagingType,
    secondaryHazardClass,
    specialPermit,
    tertiaryHazardClass,
    height,
    length,
    pieces,
    saidToContain,
    weight,
    width,
  } = product

  return {
    _id: product._id,
    autoGenerated: product.autoGenerated,
    description: product.description,
    hazardous: product.hazardous === 'Yes',
    mode: product.mode,
    nonStandard: product.nonStandard === 'Yes',
    readOnly: product.readOnly,
    stackable: product.stackable === 'Yes',
    ...(declaredValue && {
      declaredValue: {
        amount: Number(declaredValue),
        currency: 'USD',
      },
    }),
    ...(freightClass && { freightClass: Number(freightClass) }),
    ...(height && { height: Number(height) }),
    ...(length && { length: Number(length) }),
    ...(nmfc && { nmfc }),
    ...(pieces && { pieces: Number(pieces) }),
    ...(product.hazardous === 'Yes' && {
      hazard: {
        ...(hazardClass && { hazardClass }),
        ...(hazmatId && { hazmatId }),
        ...(packingGroup && { packingGroup }),
        ...(secondaryHazardClass && { secondaryHazardClass }),
        ...(specialPermit && { specialPermit }),
        ...(tertiaryHazardClass && { tertiaryHazardClass }),
      },
    }),
    ...(product.package && { package: normalizePackageType(product.package) }),
    ...(saidToContain && { saidToContain: Number(saidToContain) }),
    ...(saidToContainPackagingType && { saidToContainPackagingType }),
    ...(weight && { weight: Number(weight) }),
    ...(width && { width: Number(width) }),
  }
}
export function validateHazmatId(hazmatId: string) {
  const isValid = !hazmatId || /^(UN|NA)\d{4}$/.test(hazmatId)
  const message = isValid ? '' : 'Use UN#### or NA#### format.'
  return message
}

export function validateHazmatIdChange(e: ChangeEvent<HTMLInputElement>) {
  const hazmatId = e.target.value.toLocaleUpperCase()
  e.target.value = hazmatId

  e.target.setCustomValidity(validateHazmatId(hazmatId))
}

export function validateNmfc(nmfc: string) {
  const valid = !nmfc || /^\d{4,6}(-\d{1,2}){0,1}$/i.test(nmfc)
  return valid ? '' : 'Invalid NMFC'
}
export function validateNmfcChange(e: ChangeEvent<HTMLInputElement>) {
  const nmfc = e.target.value
  const message = validateNmfc(nmfc)
  e.target.setCustomValidity(message)
}

export function getDimensions(item: {
  dimensionsUOM?: DimensionsUOM
  height?: number | string
  length?: number | string
  width?: number | string
}): string {
  const { dimensionsUOM = 'in', length, width, height } = item
  if (!length && !width && !height) return ''

  let dimensions = ''
  if (length) dimensions += `${length}Lx`
  if (width) dimensions += `${width}Wx`
  if (height) dimensions += `${height}Hx`
  dimensions = dimensions.replace(/x$/, '') // remove trailing `x`

  return `${dimensions} ${dimensionsUOM}`
}

export function hasHazardousItems(
  loads: Array<{ items: Array<{ hazardous: 'Yes' | 'No' }> }>,
) {
  return loads.some(load => load.items.some(i => i.hazardous === 'Yes'))
}

export const getWorkflowProductMode = (workflow?: Workflow): ProductMode =>
  workflow === 'parcel' ? 'parcel' : 'ltl'

export const displayNmfc = (item?: {
  nmfcNumber?: number | string
  nmfcSubNumber?: number
}) =>
  item?.nmfcNumber
    ? !isNil(item.nmfcSubNumber)
      ? `${item.nmfcNumber}-${padStart(item.nmfcSubNumber.toString(), 2, '0')}`
      : String(item.nmfcNumber)
    : ''

export const parseNmfc = (
  nmfc?: string,
): Pick<LoadHandlingUnit, 'nmfcNumber' | 'nmfcSubNumber'> => {
  const split = nmfc?.split('-')
  const nmfcNumber = Number(split?.[0])
  const subNumber = (split?.length ?? 0) > 1 ? Number(split?.[1]) : undefined
  return {
    nmfcNumber: !nmfcNumber || isNaN(nmfcNumber) ? undefined : nmfcNumber,
    nmfcSubNumber: !subNumber || isNaN(subNumber) ? undefined : subNumber,
  }
}
