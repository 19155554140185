import { ObjectId } from '@freightview/object-id'
import omit from 'lodash/omit'
import sum from 'lodash/sum'

import {
  type HazardousDetail,
  type LegacyHandlingUnitDTO,
  type LoadHandlingUnit,
  type LoadItem,
  type PackagingType,
  type ProductCatalogEntry,
  type Workflow,
} from '@fv/client-types'

import {
  buildLegacyHandlingUnitDTO,
  displayNmfc,
  normalizePackageType,
  parseNmfc,
} from '../commodityUtils'
import {
  type LoadHandlingUnitFormModel,
  type LoadItemFormModel,
  type LoadStep,
} from './types'

export const catalogToItemData = (
  data: ProductCatalogEntry,
): LoadHandlingUnitFormModel => {
  // TODO
  // the product catalog convert legacy handling units (product catalog units) to "ProductCatalogEntry"
  // which is shouldn't need to do.  Because there are already several places where we're trying to convert product catalog entries to a load handling unit or load item or back to a product catlog unit
  const {
    hazard,
    hazardous,
    declaredValue,
    saidToContain,
    saidToContainPackagingType,
    ...handlingUnit
  } = buildLegacyHandlingUnitDTO(data)

  return {
    ...omit(handlingUnit, '_id', 'mode', 'autoGenerated'),
    handlingUnitId: handlingUnit._id,
    _id: new ObjectId().toHexString(),
    type: handlingUnit.package ?? undefined,
    declaredValue: declaredValue?.amount,
    quantity: handlingUnit.pieces,
    fullNmfc: handlingUnit.nmfc,
    isHazardous: !!Object.keys(hazard ?? {}).length,
    ...((saidToContain || saidToContainPackagingType) && {
      contains: [
        {
          _id: new ObjectId().toHexString(),
          quantity: saidToContain,
          type: saidToContainPackagingType,
        },
      ],
    }),
    ...(hazard && {
      hazardous: {
        hazmatId: hazard?.hazmatId,
        packingGroup: hazard?.packingGroup,
        permitNumber: hazard?.specialPermit,
        primaryClass: hazard?.hazardClass,
        secondaryClass: hazard?.secondaryHazardClass,
        tertiaryClass: hazard?.tertiaryHazardClass,
      },
    }),
  }
}

export const toLoadItemFormModel = (
  item: LoadItem,
  unit?: LegacyHandlingUnitDTO,
): LoadItemFormModel => {
  return {
    ...item,
    fullNmfc: displayNmfc(item),
    isHazardous: !!item.hazardous?.length,
    hazardous: item.hazardous?.[0],
    ...(unit?.readOnly && {
      readOnly: unit.readOnly,
    }),
  }
}

export const toLoadHandlingUnitFormModel = (
  item: LoadHandlingUnit,
  unit?: LegacyHandlingUnitDTO,
): LoadHandlingUnitFormModel => {
  return {
    ...toLoadItemFormModel(item, unit),
    contains: item.contains?.map(c => toLoadItemFormModel(c)),
    handlingUnitType: (item.contains?.length ?? 0) > 1 ? 'multi' : 'single',
  }
}

export const defaultLoadContainsItem = (): LoadItemFormModel => ({
  _id: new ObjectId().toHexString(),
  isHazardous: false,
})

export const defaultLoadHandlingUnit = (
  workflow: Workflow,
): LoadHandlingUnitFormModel => ({
  isHazardous: false,
  type: defaultPackagingType(workflow),
  _id: new ObjectId().toHexString(),
  handlingUnitType: 'single',
})

export function isValidItem(
  i: LoadHandlingUnitFormModel,
  workflow: Workflow,
  step: LoadStep,
) {
  const isMulti = i.handlingUnitType === 'multi'
  const needsQuantity = workflow === 'ltl' && !isMulti
  const needsWeight = !isMulti
  if ((!i.quantity && needsQuantity) || (needsWeight && !i.weight)) {
    return false
  }

  const hasAnyDims = i.length || i.width || i.height
  const hasMissingDims = !i.length || !i.width || !i.height

  if (hasAnyDims && hasMissingDims) {
    return false
  }

  if (isMulti && i.contains?.some(c => !isValidCommodity(c, step))) {
    return false
  }

  return true
}

export const isValidCommodity = (i: LoadItemFormModel, step: LoadStep) => {
  const hasWeightAndQuantity = !!i.weight && !!i.quantity
  if (step === 'quoting') {
    return hasWeightAndQuantity
  } else {
    const hasHazard = !!i.hazardous?.hazmatId && !!i.hazardous?.primaryClass
    return hasWeightAndQuantity && (!i.isHazardous || hasHazard)
  }
}

const buildHazardousDTO = (
  model: LoadItemFormModel,
): HazardousDetail | undefined => {
  if (!model.isHazardous) {
    return undefined
  }
  if (model.hazardous === undefined || !Object.keys(model.hazardous).length) {
    return {}
  }
  return {
    hazmatId: model.hazardous?.hazmatId,
    packingGroup: model.hazardous?.packingGroup,
    permitNumber: model.hazardous?.permitNumber,
    primaryClass: model.hazardous?.primaryClass,
    secondaryClass: model.hazardous?.secondaryClass,
    tertiaryClass: model.hazardous?.tertiaryClass,
  }
}

const buildContains = (
  model: LoadHandlingUnitFormModel,
  workflow: Workflow,
): LoadItem[] | undefined => {
  if (!model.contains?.length) {
    return undefined
  }
  return model.contains.map(c => {
    const itemModel = buildLoadItemDTO(c, workflow)
    return {
      ...itemModel,
    }
  })
}

export const buildLoadItemDTO = (
  model: LoadItemFormModel,
  workflow: Workflow,
): LoadItem => {
  const hazardous = buildHazardousDTO(model)
  let normalizedModel = omit(model, 'fullNmfc', 'isHazardous', 'hazardous')
  if (workflow === 'parcel') {
    normalizedModel = omit(normalizedModel, 'freightClass')
    normalizedModel.quantity = 1
  }
  return {
    ...normalizedModel,
    ...parseNmfc(model.fullNmfc),
    ...(hazardous && { hazardous: [hazardous] }),
    description: model.description ?? '',
    weightUOM: model.weight ? 'lbs' : undefined,
  }
}

export const buildHandlingUnitDTO =
  (workflow: Workflow) =>
  (model: LoadHandlingUnitFormModel): LoadHandlingUnit => {
    const contains = buildContains(model, workflow)
    const itemModel = buildLoadItemDTO(model, workflow)
    return {
      ...itemModel,
      quantity: itemModel.quantity ?? 1,
      dropLocationSequence: model.dropLocationSequence ?? 1,
      pickupLocationSequence: model.pickupLocationSequence ?? 0,
      type: normalizePackageType(model.type ?? defaultPackagingType(workflow)),
      contains,
      weight: handlingUnitWeight(model),
      weightUOM: 'lbs',
    }
  }

export const defaultPackagingType = (workflow: Workflow): PackagingType =>
  workflow === 'parcel' ? 'custom' : 'pallet'

export const handlingUnitWeight = (unit: LoadHandlingUnitFormModel): number => {
  if (unit.handlingUnitType === 'single') {
    return unit.weight ?? 0
  }
  return sum(unit.contains?.map(c => c.weight ?? 0))
}

export const unitIsHazardous = (unit: LoadHandlingUnitFormModel) =>
  unit.isHazardous || unit.contains?.some(c => c.isHazardous)
