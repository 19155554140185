import { ObjectId } from '@freightview/object-id'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'

import {
  type LocationType,
  type ShipmentLocation,
  type StopType,
  type Workflow,
} from '@fv/client-types'
import {
  type Country,
  type LegacyShipType,
  type LocationAccessorialType,
} from '@fv/models'

import { toFormLocation } from '../features/booking/hooks'
import { type FormLocation } from '../types/FormLocation'

export const insideShipTypes: LegacyShipType[] = [
  'airport',
  'business no dock',
  'farm',
  'limited access',
  'military',
  'mine',
  'place of worship',
  'residential',
  'school',
  'prison',
]

export const liftgateShipTypes = insideShipTypes.concat('construction')

export function hasInsideOption(shipType: '' | LegacyShipType) {
  return !!shipType && insideShipTypes.includes(shipType)
}

export function hasLiftgateOption(shipType: '' | LegacyShipType) {
  return !!shipType && liftgateShipTypes.includes(shipType)
}

export function sanitizeLocation<
  T extends Pick<FormLocation, 'accessorials' | 'shipType'>,
>(location: T, workflow?: Workflow): T {
  location = cloneDeep(location)

  if (workflow === 'parcel') {
    location.shipType =
      location.shipType === 'residential' || !location.shipType
        ? location.shipType
        : 'business dock'
  }

  // Remove invalid accessorials
  if (location.shipType) {
    const { shipType } = location
    location.accessorials = location.accessorials.filter(a => {
      if (a.key === 'inside') return insideShipTypes.includes(shipType)
      if (a.key === 'liftgate') {
        return liftgateShipTypes.includes(shipType)
      }
      return true
    })
  }

  return location
}

export function formStopsToLocationsDTO(
  stops: FormLocation[],
): ShipmentLocation[] {
  return stops.map<ShipmentLocation>(stop => {
    let type: LocationType = stop.sequence === 0 ? 'origin' : 'stop'
    if (stop.sequence === stops.length - 1) type = 'destination'

    const address = stop.address.trim()
    const address2 = stop.address2.trim()
    const adminArea3 = stop.adminArea3?.trim()
    const contactEmail = stop.contactEmail.trim()
    const contactName = stop.contactName.trim()
    const contactPhone = stop.contactPhone.trim()
    const instructions = stop.instructions.trim()
    const refNum = stop.refNum?.trim()

    return {
      accessorials: stop.accessorials,
      city: stop.city,
      country: stop.country.toLowerCase() as Country,
      lat: stop.lat,
      lng: stop.lng,
      postalCode: stop.postalCode,
      sequence: stop.sequence,
      state: stop.state,
      stopDateType: stop.stopDateType,
      stopType: stop.stopType,
      timezone: stop.timezone,
      opensAt: stop.opensAt,
      closesAt: stop.closesAt,
      type,
      ...(address && { address }),
      ...(address2 && { address2 }),
      ...(adminArea3 && { adminArea3 }),
      ...(contactEmail && { contactEmail }),
      ...(contactName && { contactName }),
      ...(contactPhone && { contactPhone }),
      ...(instructions && { instructions }),
      ...(refNum && {
        refNums: [{ type: 'other', value: refNum }],
      }),
      ...(stop.accountLocationId && {
        accountLocationId: stop.accountLocationId,
      }),
      ...(stop.addressBookId && { addressBookId: stop.addressBookId }),
      ...(stop.company && { company: stop.company }),
      ...(stop.shipType && { shipType: stop.shipType }),
      ...(stop.stopDate && {
        stopDate: dayjs(stop.stopDate).format('YYYY-MM-DD'),
      }),
    }
  })
}

export function locationToFormStop(
  x: ShipmentLocation,
  workflow?: Workflow,
): FormLocation {
  return sanitizeLocation(
    toFormLocation(x, { workflow: workflow ?? 'ltl' }),
    workflow,
  )
}

export function buildStop({
  sequence,
  shipType,
  stopDate = null,
  stopType,
}: {
  sequence: number
  shipType?: LegacyShipType
  stopDate?: Date | null
  stopType: StopType
}): FormLocation {
  return {
    accessorials: [],
    accountLocationId: '',
    address: '',
    address2: '',
    addressBookId: '',
    city: '',
    closesAt: '17:00',
    contactPhoneExt: '',
    copyBOL: false,
    company: '',
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    country: 'us',
    instructions: '',
    type: stopType === 'pickup' ? 'origin' : 'destination',
    lat: 0,
    lng: 0,
    opensAt: '08:00',
    postalCode: '',
    refNum: '',
    sequence,
    shares: '',
    shipType: shipType || '',
    state: '',
    stopDate,
    stopDateType: 'on',
    stopType,
    timezone: '',

    // Front-end only
    id: new ObjectId().toHexString(),
  }
}

export function getApplicableAccessorials(
  location: Pick<ShipmentLocation, 'type' | 'accessorials'>,
  workflow: Workflow,
) {
  if (location.type !== 'origin' || workflow === 'truckload') {
    return location.accessorials ?? []
  }

  const nonOriginAccessorials: LocationAccessorialType[] = [
    'schedule',
    'notify',
  ]

  return (
    location.accessorials?.filter(
      a => !nonOriginAccessorials.includes(a.key),
    ) ?? []
  )
}

export const isPuertoRicanLoc = ({ state }: Pick<ShipmentLocation, 'state'>) =>
  state === 'PR'
