import { useMutation } from '@tanstack/react-query'

import {
  type SubscriptionFundingDto,
  type UpdateSubscriptionSettingsDto,
} from '@fv/models'

import { shipperFetch } from '../../../utils/shipperFetch'

export const useSetupTransferSubscription = () => {
  return useMutation(() =>
    shipperFetch<SubscriptionFundingDto>('/subscription/transfer-account', {
      method: 'POST',
    }),
  )
}

export const useSetupEasySubscription = () => {
  return useMutation(() =>
    shipperFetch<SubscriptionFundingDto>('/subscription/easy-account', {
      method: 'POST',
    }),
  )
}

export const useUpdateSubscriptionSettings = () => {
  return useMutation((model: UpdateSubscriptionSettingsDto) =>
    shipperFetch('/subscription', {
      method: 'PUT',
      body: model,
    }),
  )
}
