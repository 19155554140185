import { ObjectId } from '@freightview/object-id'
import dayjs from 'dayjs'
import { Navigate } from 'react-router-dom'

import { Icon } from '@fv/client-components'
import { type FullShipment, type QuoteRequestDTO } from '@fv/client-types'

import { routes } from '../../constants'
import { useAddresses } from '../addresses/useAddress'
import { useHandlingUnits } from '../commodities/useHandlingUnits'
import { BookingForm } from './BookingForm'
import { BookingFormProvider } from './BookingFormProvider'

export const crStorageKey = 'customer-routed'

export const CustomerRoutedBookingPage = () => {
  const crData = localStorage.getItem(crStorageKey)
  const parsed = JSON.parse(String(crData)) as QuoteRequestDTO | null

  const [origin, dest] = parsed?.locations ?? []
  const createdDate = dayjs.utc().toString()
  const equip = parsed?.equipment[0]
  const workflow = parsed?.workflow ?? 'ltl'

  const load: FullShipment | null =
    parsed && dest && equip
      ? {
          accountId: '',
          createdDate,
          equipment: {
            accessorials: equip.accessorials,
            alternateTypes: equip.alternateTypes,
            declaredValue: equip.declaredValue,
            declaredValueCurrency: equip.declaredValueCurrency,
            description: equip.description,
            isHazardous: equip.isHazardous,
            mode: equip.mode,
            type: equip.type,
            weight: equip.weight,
            weightUOM: equip.weightUOM,
          },
          isArchived: false,
          isFreightCollect: true,
          isLiveLoad: true,
          isMultiTruck: false,
          items: equip.items || [],
          loadId: new ObjectId().toHexString(),
          locations: parsed.locations.map(x => ({
            ...x,
            stopDate: noonUtc(x.stopDate),
          })),
          newMessageCount: 0,
          pickupDate: noonUtc(origin.stopDate) || createdDate,
          quotedByUserId: '',
          quoteRequestId: new ObjectId().toHexString(),
          refNums: [],
          status: 'pending',
          workflow,
        }
      : null

  const addressesQuery = useAddresses(load)
  const handlingUnitsQuery = useHandlingUnits(load)

  if (!load) return <Navigate replace to={routes.quote(workflow)} />

  if (addressesQuery.loading || handlingUnitsQuery.loading) {
    return (
      <div className="parcel-ltl-booking-main row-start-2">
        <Icon icon="sync" />
        <span className="ml-2">Loading...</span>
      </div>
    )
  }

  return (
    <BookingFormProvider
      addresses={addressesQuery.data}
      handlingUnits={handlingUnitsQuery.data}
      load={load}
      isNew={true}
    >
      <BookingForm />
    </BookingFormProvider>
  )
}

function noonUtc(date?: string | null) {
  if (!date) return date
  return dayjs.utc(date).add(12, 'hours').toString()
}
