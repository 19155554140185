import {
  type Audit as _Audit,
  type LoadDocumentDTO as _LoadDocumentDTO,
  type QuoteDTO as _QuoteDTO,
} from '@fv/models'
import { type DTO } from '@fv/models/core'

export const colorSuccess = 'color-success'

export type Audit = DTO<_Audit>
export type QuoteDTO = DTO<_QuoteDTO>
export type LoadDocumentDTO = DTO<_LoadDocumentDTO>

export type ReconciledChargeItem = {
  label: string
  amount: number
  chargeName: string
}
