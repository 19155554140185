import { useQuery } from '@tanstack/react-query'
import LogRocket from 'logrocket'

import {
  appVersion,
  buildFetchOptionsWithAuth,
  fetchJson,
} from '@fv/client-core'
import { type ShipperUserContext } from '@fv/models'

import { apiUri, logrocketApp } from '../../constants'

async function fetchAppContext(): Promise<{ user: ShipperUserContext }> {
  const endpoint = `${apiUri}/context`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export const appContextQueryKey = ['app-context']
export function useAppContext(enabled = true) {
  return useQuery(appContextQueryKey, fetchAppContext, {
    enabled,
    onSuccess: ({ user }) => {
      if (!logrocketApp) return

      LogRocket.init(`d6lkaq/${logrocketApp}`, {
        rootHostname: 'freightview.com',
        release: appVersion,
      })

      LogRocket.identify(user._id, {
        accountId: user.accountIds[0],
        email: user.email,
        isAdmin: user.isAdmin,
        name: user.firstName + ' ' + user.lastName,
      })
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}

export const useAccountId = () => {
  return useAppContext().data?.user?.accountIds?.[0] ?? undefined
}

export const useCurrentUser = () => {
  const context = useAppContext()
  if (!context.data.user) {
    throw new Error('User data not present')
  }
  return context.data.user
}
