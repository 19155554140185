import { useQuery } from '@tanstack/react-query'

import { type SystemNotification } from '@fv/models'
import { type DTO } from '@fv/models/core'

import { shipperFetch } from '../../utils/shipperFetch'

export const systemNotificationQueryKeys = {
  all: ['system-notifications'],
}

export const useSystemNotifications = () => {
  return useQuery(
    systemNotificationQueryKeys.all,
    () => shipperFetch<Array<DTO<SystemNotification>>>('/notifications/system'),
    {},
  )
}
