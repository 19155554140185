import dayjs from 'dayjs'
import pluralize from 'pluralize'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import { FvButton, FvNum, SliderPanel } from '@fv/client-components'
import { freightviewUri } from '@fv/client-core'

import { FormActions } from '../../../components/forms/FormActions'
import InfoBox from '../../../components/InfoBox'
import { ValidatedForm } from '../../../components/inputs'
import { List, ListItem } from '../../../components/List'
import { Loading } from '../../../components/Loading'
import { supportMessage } from '../../../constants'
import { useAccountFeatures } from '../../auth'
import { SettingsPageLayout } from '../SettingsPageLayout'
import { SettingsSection } from '../SettingsSection'
import { useRedirectToStripePortal, useSubscriptionContext } from './hooks'
import {
  useSetupEasySubscription,
  useSetupTransferSubscription,
} from './mutations'
import { useSubscriptionContextQuery } from './queries'
import { ReceiptEmailsForm } from './ReceiptEmailsForm'
import { StripeCustomerPortalButton } from './StripeCustomerPortalButton'
import { SubscriptionsSetupPage } from './SubscriptionsSetupPage'
import { SubscriptionStatusAlert } from './SubscriptionStatusAlert'

export const SubscriptionPage = () => {
  const { subscriptionsV2 } = useAccountFeatures()
  const query = useSubscriptionContextQuery()

  if (!subscriptionsV2) {
    window.location.href = `${freightviewUri}/app#subscription`
    return
  }

  if (query.isLoading || !query.data) {
    return (
      <SettingsPageLayout>
        <Loading />
      </SettingsPageLayout>
    )
  }

  if (query.data.needsSetup) {
    return <SubscriptionsSetupPage />
  }

  return (
    <SettingsPageLayout>
      <SubscriptionStatusAlert />
      <SettingsSection title="Your subscription">
        <List>
          <ListItem>
            <SubscriptionDesciption />
          </ListItem>
          {query.data.type === 'ach_credit_transfer' && (
            <>
              <ListItem>
                <FundingInfo />
              </ListItem>
              <ListItem>
                <FundingBalance />
              </ListItem>
            </>
          )}
          <ListItem>
            <ReceiptEmailsForm />
          </ListItem>
        </List>
        <SubscriptionActions />
      </SettingsSection>
    </SettingsPageLayout>
  )
}

const FundingInfo = () => {
  const sub = useSubscriptionContext()

  if (!sub.funding) return null

  return (
    <span>
      Routing# {sub.funding?.routingNumber} / Account#{' '}
      {sub.funding?.accountNumber}
    </span>
  )
}

const FundingBalance = () => {
  const sub = useSubscriptionContext()

  if (!sub.funding) return null
  return (
    <span>
      <span className="px-2 py-1 bg-fv-beer border-fv-beer-dark border">
        <Amount val={sub.balance / 100} />
      </span>{' '}
      balance for funding
    </span>
  )
}

const SubscriptionActions = () => {
  const [showTransfer, toggleShowTransfer] = useToggle()
  const [showRemoveACHCredit, toggleRemoveACHCredit] = useToggle()
  const sub = useSubscriptionContext()
  const setupTransfer = useSetupTransferSubscription()
  const setupEasy = useSetupEasySubscription()
  const stripePortal = useRedirectToStripePortal()

  const handleTransferSubmit = async () => {
    try {
      await setupTransfer.mutateAsync()
      window.location.reload()
    } catch {
      toast.error(
        `There was an error modifying your subscription ${supportMessage}`,
      )
    }
  }

  const handleRemoveSubmit = async () => {
    await setupEasy.mutateAsync()
    await stripePortal.redirect()
  }
  return (
    <div className="flex gap-x-2">
      <StripeCustomerPortalButton>
        Manage my subscription
      </StripeCustomerPortalButton>

      {sub.type !== 'ach_credit_transfer' && (
        <FvButton theme="primary" onClick={toggleShowTransfer}>
          Setup ACH credit transfer
        </FvButton>
      )}
      {sub.type === 'ach_credit_transfer' && (
        <FvButton theme="primary" onClick={toggleRemoveACHCredit}>
          Change payment method
        </FvButton>
      )}

      <SliderPanel isOpen={showTransfer} closePanel={toggleShowTransfer}>
        <InfoBox>
          By clicking continue below, you'll be switching your default payment
          method for your subscription. You'll be provided bank account
          information that you'll be responsible to transfer funds to before or
          on your designated renewal date. You will not be able to use credit
          card or ACH debit as payment methods if you choose to continue.
        </InfoBox>
        <ValidatedForm onValidSubmit={handleTransferSubmit}>
          <FormActions
            jstart
            cancelText="Cancel"
            submitText="Continue"
            onCancel={toggleShowTransfer}
            loading={setupTransfer.isLoading}
          />
        </ValidatedForm>
      </SliderPanel>

      <SliderPanel
        isOpen={showRemoveACHCredit}
        closePanel={toggleRemoveACHCredit}
      >
        <InfoBox>
          By clicking continue below, you'll be switching your default payment
          method for your subscription. You'll be taken to Stripe where you can
          enter your new payment method. You will lose access to the routing &
          account number for your ACH credit transfer. Any remaining balance
          will be returned.
        </InfoBox>
        <ValidatedForm onValidSubmit={handleRemoveSubmit}>
          <FormActions
            jstart
            cancelText="Cancel"
            submitText="Continue"
            onCancel={toggleRemoveACHCredit}
            loading={setupEasy.isLoading || stripePortal.loading}
          />
        </ValidatedForm>
      </SliderPanel>
    </div>
  )
}

const Amount = ({ val }: { val: number }) => (
  <FvNum className="text-xl font-oxygen" currency val={val} />
)
const SubscriptionDesciption = () => {
  const sub = useSubscriptionContext()
  if (!sub || !sub.pricing) return null

  let intervalString = 'Monthly'
  if (sub.pricing.interval === 'month' && sub.pricing.intervalCount === 12) {
    intervalString = 'Anually'
  } else if (
    sub.pricing.interval !== 'month' ||
    sub.pricing.intervalCount !== 1
  ) {
    intervalString = `every ${sub.pricing.intervalCount} ${pluralize(sub.pricing.interval, sub.pricing.intervalCount)}`
  }

  return (
    <div>
      <Amount val={sub.pricing.amount / 100} /> {intervalString}
      &nbsp;/&nbsp; Next due date is {dayjs.utc(sub.ends).format('MM/DD/YYYY')}
    </div>
  )
}
