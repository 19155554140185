import { isHolidayOrWeekend } from '@freightview/holidays'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { shallow } from 'zustand/shallow'

import {
  DelimitedContent,
  FvButton,
  Modal,
  useFvNavigate,
  ValidatedForm,
} from '@fv/client-components'
import { isApiError, submitForm } from '@fv/client-core'

import { hasMixedPallets } from '../../../../../../packages/models/src/core'
import { Loading } from '../../components/Loading'
import { LoadSummary } from '../../components/LoadSummary/LoadSummary'
import { routes, supportMessage } from '../../constants'
import { useUserSettings } from '../../hooks/settings'
import { useQuotes } from '../../hooks/shipments'
import { quoteKeys } from '../../hooks/shipments/useQuotes'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import {
  isBookedAndDispatched,
  originStopDate,
} from '../../utils/shipmentFuncs'
import { addressBookKeys } from '../addresses/useAddressBookAddresses'
import { BillTo } from '../billTo/BillTo'
import { toLocationDTO } from '../booking/hooks'
import { useAwardLoads } from '../booking/mutations'
import { LTLBroker } from '../customsBroker/LTLBroker'
import { LTLContact } from '../emergencyContact/LTLContact'
import { quoteFuncs } from '../quote/quoteFuncs'
import { QuoteReasonSlider } from '../quote-reason/QuoteReasonForm'
import { useCarrierBookingFormSettings } from './bookFuncs'
import { LtlBookingBolField } from './BookingFormBolField'
import { BookingFormItems } from './BookingFormItems'
import { BookingFormLocation } from './BookingFormLocation'
import { useBookingFormCtx, useBookingFormLoad } from './BookingFormProvider'
import { LtlBookingFormShare } from './BookingFormShare'
import { LtlBookingFormTags } from './BookingFormTags'
import { BookingRateDetails } from './BookingRateDetail'
import { CustomerRoutedForm } from './CustomerRoutedForm'
import { ErrorNotice } from './ErrorNotice'
import { FedExHomeDeliveryBooking } from './FedExHomeDelivery'
import { FedExShipAlert } from './FedExShipAlert'
import { HoldAtLocationForm } from './HoldAtLocationForm'
import { LTLRateLocation } from './LTLRateLocation'
import { RateChangeNotice } from './RateChangeNotice'
import { ReratingOverlay } from './ReratingOverlay'
import { type BuildAwardRequestArgs } from './types'
import { useCreateCustomerRoutedLoad } from './useCreateCustomerRouted'
import { useCustomerRoutedCarriers } from './useCustomerRoutedCarriers'

export const BookingForm = () => {
  const queryClient = useQueryClient()
  const actions = useBookingFormCtx(s => s.actions)
  const workflow = useBookingFormCtx(s => s.workflow)
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const isParcel = workflow === 'parcel'
  const isLtl = workflow === 'ltl'
  const addCRLoad = useCreateCustomerRoutedLoad()
  const awardLoads = useAwardLoads()
  const canSchedulePickup = useCanSchedulePickup()
  const canSchedulePickupOnly = useCanSchedulePickupOnly()
  const canDoBolOnly = useCanRequestBolOnly()
  const formRef = useRef<HTMLFormElement>(null)
  const isEdit = useBookingFormCtx(s => s.isEdit)
  const load = useBookingFormLoad()
  const navigate = useFvNavigate()
  const { validateItems } = useBookingFormCtx(s => s.itemActions)
  const quote = useBookingFormCtx(s => s.quote)
  const [bookCache, setBookCache] = useState<{
    createBOL: boolean
    schedulePickup: boolean
  }>()
  const [rerating, setRerating] = useState(
    load?.selectedQuote?._id === quote?._id &&
      (load?.quoteProcessStatus === 'rerating' ||
        load?.quoteProcessStatus === 'rerate-ready'),
  )
  const { invalidate: clearQuoteCache } = useQuotes(load?.loadId, {
    enabled: false,
  })
  const isBusy =
    addCRLoad.isLoading ||
    addCRLoad.isSuccess ||
    awardLoads.isLoading ||
    awardLoads.isSuccess
  const [errorList, setErrorList] = useState<string[]>()
  const {
    billTo,
    bolNumber,
    locations,
    shareEmails,
    tags,
    itemState,
    equipmentAccessorials,
  } = useBookingFormCtx(s => s)

  useEffect(() => {
    setErrorList([])
  }, [
    billTo,
    bolNumber,
    locations,
    shareEmails,
    tags,
    itemState,
    equipmentAccessorials,
  ])

  useEffect(() => {
    queryClient.invalidateQueries(quoteKeys.rerated(load?.loadId))
  }, [queryClient, rerating, load])

  function bookShipment({
    createBOL,
    schedulePickup,
    quoteId,
  }: BuildAwardRequestArgs) {
    setErrorList([])
    if (isBusy) return
    if (!formRef.current?.checkValidity()) {
      return submitForm(formRef.current)
    }

    const itemsValid = validateItems(workflow, 'booking')
    if (!itemsValid) {
      return submitForm(formRef.current)
    }

    setBookCache({ schedulePickup: Boolean(schedulePickup), createBOL })

    if (isFreightCollect && !isEdit) {
      const dto = actions.buildCustomerRoutedDTO(createBOL, schedulePickup)

      addCRLoad.mutate(dto, {
        onError: error => {
          if (isApiError(error)) {
            setErrorList(
              error.message
                ? [error.message]
                : error.errors?.map(e => e.message),
            )
          } else {
            setErrorList([`There was an error, ${supportMessage}`])
          }
        },
        onSuccess: loads => {
          navigate(routes.details(loads[0].loadId))
        },
      })
    } else {
      const dto = actions.buildRequestDTO({
        createBOL,
        schedulePickup,
        quoteId,
      })

      awardLoads.mutate(dto, {
        onSuccess: ({ loads }) => {
          const { loadId, quoteProcessStatus, status } = loads[0]

          if (quoteProcessStatus === 'rerating') {
            awardLoads.reset()
            setRerating(true)
          } else {
            clearQuoteCache()
            queryClient.invalidateQueries(addressBookKeys.all)
            navigate(
              status === 'pending'
                ? routes.rates(loads[0])
                : routes.details(loadId),
            )
          }
        },
        onError: error => {
          console.warn({ error })

          const err = error as any
          let msg = err.message

          if (err.errors?.length) {
            const { propertyName, message } = err.errors[0]
            msg += ` "${propertyName}" ${message?.toLowerCase()}.`
          }

          setErrorList(msg)
          toast.error(msg)
        },
      })
    }
  }

  useEffect(() => {
    if (load?.pickup?.status === 'error') {
      setTimeout(
        // force this hook to happen after the clearing out hook
        () =>
          setErrorList([
            load?.pickup?.error || `Unable to book shipment, ${supportMessage}`,
          ]),
        0,
      )
    }
  }, [load?.loadId, load?.pickup?.error, load?.pickup?.status]) // leave load?.loadId as a dependency otherwise errors get cleared out by other hooks

  // If the customer is editing a load after booking, we want to go ahead and redirect the customer to
  // the details page once the re-rate process finishes even if no matching quote was found.
  useEffect(() => {
    if (
      (load?.quoteProcessStatus === 'rerate-no-compat' ||
        load?.quoteProcessStatus === 'ok') &&
      rerating &&
      isBookedAndDispatched(load)
    ) {
      navigate(routes.details(load.loadId))
    }
  }, [load, rerating, navigate])

  if (!load) {
    return <Loading />
  }

  return (
    <>
      <LoadSummary
        load={{
          ...load,
          locations: locations.map(toLocationDTO),
        }}
        isEditingLoad
        onDateEdit={(sequence, date) => {
          actions.setLocationValues(sequence === 1 ? 'destination' : 'origin', {
            stopDate: date,
          })
        }}
        quote={quote}
      />

      <div className="parcel-ltl-booking-main row-start-2 [scrollbar-gutter:stable]">
        {!!errorList?.length && <ErrorNotice errors={errorList} />}
        {quote && <BookingRateDetails load={load} quote={quote} />}
        <ValidatedForm ref={formRef}>
          <CustomerRoutedForm disabled={isBusy} />
          <BookingFormLocation disabled={isBusy} type="origin" />
          <BookingFormLocation disabled={isBusy} type="destination" />
          {isParcel && <FedExHomeDeliveryBooking />}
          {isParcel && <HoldAtLocationForm />}
          <BookingFormItems disabled={isBusy} />

          <hr className="mb-12" />

          {isParcel && <FedExShipAlert />}

          {isLtl && (
            <>
              <LTLContact disabled={isBusy} />
              <LTLBroker disabled={isBusy} />
              <LTLRateLocation disabled={isBusy} />
            </>
          )}
          <BillTo disabled={isBusy} />
          <div className="gray-container flex gap-2 px-4 pb-2 pt-4">
            {workflow !== 'parcel' && (
              <LtlBookingBolField className="basis-3/12" />
            )}
            <LtlBookingFormShare
              className={clsx({
                'basis-5/12': isLtl,
                'basis-6/12': isParcel,
              })}
              disabled={isBusy}
            />
            <LtlBookingFormTags
              className={clsx({
                'basis-4/12': isLtl,
                'basis-6/12': isParcel,
              })}
            />
          </div>
          <RateChangeNotice />
        </ValidatedForm>
      </div>

      <div className="footer-actions row-start-[-1] col-start-1 col-span-3">
        <DelimitedContent delimiter={<span className="ml-2 mr-2">-or-</span>}>
          {!isEdit && canSchedulePickupOnly && (
            <FvButton
              loading={isBusy}
              onClick={() =>
                bookShipment({
                  createBOL: false,
                  schedulePickup: true,
                })
              }
            >
              Submit pickup request & upload BOL
            </FvButton>
          )}
          {isEdit && workflow !== 'parcel' && (
            <FvButton
              loading={isBusy}
              icon="check"
              theme="primary"
              fwd
              className="mr-1"
              onClick={() =>
                bookShipment({
                  createBOL: load.bol?.status !== 'not-requested',
                  schedulePickup: false,
                })
              }
            >
              Save Changes
            </FvButton>
          )}
          {!isEdit && canDoBolOnly && workflow !== 'parcel' && (
            <FvButton
              loading={isBusy}
              icon="file-contract"
              theme="default"
              onClick={() =>
                bookShipment({
                  createBOL: true,
                  schedulePickup: false,
                })
              }
            >
              Print BOL
            </FvButton>
          )}
          {!isEdit && canSchedulePickup && workflow !== 'parcel' && (
            <FvButton
              theme="primary"
              fwd
              icon={isBusy ? 'spinner' : 'truck'}
              onClick={() =>
                bookShipment({
                  createBOL: true,
                  schedulePickup: true,
                })
              }
            >
              Schedule pickup & print BOL
            </FvButton>
          )}
          {workflow === 'parcel' && (
            <FvButton
              fwd
              icon={isBusy ? 'spinner' : 'truck'}
              onClick={() =>
                bookShipment({
                  createBOL: true,
                  schedulePickup: load.schedulePickup,
                })
              }
              theme="primary"
            >
              Create Shipment and Labels
            </FvButton>
          )}
          {isEdit && (
            <FvButton icon="times" theme="default" onClick={() => navigate(-1)}>
              Cancel
            </FvButton>
          )}
        </DelimitedContent>
      </div>

      {rerating && (
        <Modal>
          <ReratingOverlay
            loadId={load.loadId}
            onAcceptRerate={quoteId => {
              clearQuoteCache()
              if (bookCache) {
                bookShipment({
                  ...bookCache,
                  quoteId,
                })
              } else {
                setRerating(false)
                navigate(routes.book({ loadId: load.loadId, quoteId }), {
                  replace: true,
                })
              }
            }}
            onDeclineRerate={() => {
              queryClient.invalidateQueries(quoteKeys.load(load.loadId))
              setRerating(false)
            }}
          />
        </Modal>
      )}
      {quote && load && <QuoteReasonSlider load={load} quote={quote} />}
    </>
  )
}

// Legacy: src/client/app/models/7-shipment-book.coffee
function useCanSchedulePickup() {
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const workflow = useBookingFormCtx(s => s.workflow)
  const settingsQuery = useUserSettings()
  const accountCarriers = settingsQuery.data?.carriers ?? []
  const locations = useBookingFormCtx(s => s.locations)
  const items = useBookingFormCtx(s => s.itemState.items)
  const { data: crData } = useCustomerRoutedCarriers(workflow)
  const carrierSettings = useCarrierBookingFormSettings()
  const { carrierId, selectedQuote } = useBookingFormCtx(
    s => ({
      carrierId: s.customerRoutedDetail.carrier?.id,
      selectedQuote: s.quote,
    }),
    shallow,
  )
  const crCarrierSettings = useCarrierPublicSettings(carrierId)
  const carrier = crData?.carriers?.find(c => c.id === carrierId)

  const pickupDate = originStopDate({
    locations: locations.map(loc => ({
      type: loc.type,
      stopDate: loc.stopDate?.toISOString(),
    })),
  })

  // TODO - any reason to check carrier here if there's no seleted quote?
  const provisionedCarrier = accountCarriers.find(
    c => c._id === carrierId || c._id === selectedQuote?.carrierId,
  )

  if (quoteFuncs.isSpotWorkflow(selectedQuote)) return true
  if (hasMixedPallets(items)) return false

  const carrierDispatchDisabled = carrierSettings?.dispatchMethod === 'disabled'
  if (carrierDispatchDisabled) {
    return false
  }
  const isManualQuote = !selectedQuote || selectedQuote.method === 'manual'
  if (
    !isFreightCollect &&
    isManualQuote &&
    (provisionedCarrier?.status !== 'visible' ||
      carrierSettings?.status !== 'active')
  ) {
    return false
  }
  if (
    isFreightCollect &&
    (carrier?.status !== 'active' ||
      crCarrierSettings?.carrierPublicSettings?.dispatchMethod === 'disabled')
  ) {
    return false
  }

  return !isHolidayOrWeekend(pickupDate)
}

function useCanRequestBolOnly() {
  const carrierSetting = useCarrierBookingFormSettings()
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  if (isFreightCollect) return true
  return carrierSetting?.allowBolOnly
}

function useCanSchedulePickupOnly() {
  const selectedQuote = useBookingFormCtx(s => s.quote)
  return quoteFuncs.isSpotWorkflow(selectedQuote)
}
