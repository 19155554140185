import { ObjectId } from '@freightview/object-id'
import sum from 'lodash/sum'
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useRef,
} from 'react'
import toast from 'react-hot-toast'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { create, useStore } from 'zustand'

import { setPathValue, setPathValues, submitForm } from '@fv/client-core'
import {
  type FullShipment,
  type LegacyHandlingUnitDTO,
  type QuoteStopType,
  type Workflow,
} from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { routes } from '../../constants'
import { useLoad } from '../../hooks/shipments'
import { type FormLocation } from '../../types/FormLocation'
import {
  buildStop,
  formStopsToLocationsDTO,
  locationToFormStop,
  sanitizeLocation,
} from '../../utils/locationFuncs'
import { defaultStopDate } from '../../utils/shipmentFuncs'
import { requireRateLocation } from '../book/bookFuncs'
import {
  buildHandlingUnitDTO,
  toLoadHandlingUnitFormModel,
  unitIsHazardous,
} from '../commodities/load-items/loadItemHelpers'
import {
  createItemsStore,
  createItemsStoreFromLoad,
  type LoadItemsStore,
} from '../commodities/load-items/loadItemsFormState'
import { useHandlingUnits } from '../commodities/useHandlingUnits'
import { useAppPreferences } from '../settings/user-preferences/hooks'
import { quoteRequestMapper, type QuoteRequestMode } from './quoteFuncs'
import {
  type QuoteFormSettings,
  type QuotePageActions,
  type QuotePageState,
} from './types'
import { useRecentShipment } from './useRecentShipment'

const defaultOrigin = (): FormLocation =>
  buildStop({
    sequence: 0,
    shipType: 'business dock',
    stopDate: defaultStopDate('origin'),
    stopType: 'pickup',
  })

const defaultDestination = (): FormLocation =>
  buildStop({
    sequence: 1,
    shipType: 'business dock',
    stopType: 'delivery',
  })

const getInitialState = (): Omit<QuotePageState, keyof LoadItemsStore> => ({
  load: {
    isFreightCollect: false,
    loadId: new ObjectId().toHexString(),
    protectFromFreezing: false,
    quoteRequestId: '',
    workflow: 'ltl',
    rateLocationType: 'origin',
  },
  locations: [defaultOrigin(), defaultDestination()],
  routeFormRef: null,
  settings: {
    autoFillDestination: false,
    autoFillOrigin: false,
    autoFillProduct: false,
  },
})

export function createQuotePageStore(props: {
  handlingUnits: LegacyHandlingUnitDTO[]
  load?: FullShipment
  mode?: QuoteRequestMode
  settings: QuoteFormSettings
  recentLoad?: FullShipment
  workflow: Workflow
  recentUnits: LegacyHandlingUnitDTO[]
  createFreightCollect?: boolean
}) {
  const { handlingUnits, load, mode, recentLoad, settings, workflow } = props
  const initializeFromLoad = !!load && !!mode
  const initializeFromRecentData = !load && !mode && !!recentLoad
  const initialState = getInitialState()
  return create<LoadItemsStore & QuotePageActions & QuotePageState>(
    (...store) => {
      const [set, get] = store
      return {
        ...initialState,
        ...createItemsStore({ workflow, step: 'quoting' })(...store),
        load: {
          ...initialState.load,
          workflow,
          schedulePickup: workflow !== 'parcel' || !!load?.schedulePickup,
          ...(props.createFreightCollect && {
            isFreightCollect: true,
          }),
        },
        ...(initializeFromLoad &&
          createItemsStoreFromLoad({
            load,
            step: 'quoting',
            handlingUnitLookup: handlingUnits,
          })(...store)),
        ...(initializeFromLoad &&
          quoteRequestMapper.ltlRequest({
            load,
            mode,
          })),
        ...(initializeFromRecentData && {
          ...((settings.autoFillOrigin || settings.autoFillDestination) && {
            locations: initialState.locations.map(x => {
              if (x.sequence === 0 && settings.autoFillOrigin) {
                return locationToFormStop(
                  {
                    ...recentLoad.locations[0],
                    refNums: [],
                    stopDate: new Date().toISOString(),
                  },
                  workflow,
                )
              }

              if (x.sequence === 1 && settings.autoFillDestination) {
                return locationToFormStop(
                  {
                    ...recentLoad.locations[1],
                    refNums: [],
                    stopDate: undefined,
                  },
                  workflow,
                )
              }

              return x
            }),
          }),
          ...(settings.autoFillProduct && {
            ...createItemsStoreFromLoad({
              load: recentLoad,
              step: 'quoting',
              handlingUnitLookup: props.recentUnits,
              refreshIds: true,
            })(...store),
          }),
        }),
        settings,
        actions: {
          buildRequest: () => {
            const {
              actions,
              itemState,
              load,
              locations,
              deliveryOption,
              itemActions,
            } = get()
            const items = itemState.items
            if (mode !== 'return') {
              const isValidItemForm = itemActions.validateForm()
              const isValidRoute = actions.validateForm()
              if (!isValidItemForm || !isValidRoute) return

              const itemsValid = itemActions.validateItems(workflow, 'quoting')
              if (!itemsValid) {
                return
              }
            }

            // TODO: turnable?
            const declaredValue = items.reduce(
              (value, item) => value + Number(item.declaredValue || 0),
              0,
            )
            const itemDtos = itemState.items.map(buildHandlingUnitDTO(workflow))
            return {
              ...(requireRateLocation(locations, load.isFreightCollect) && {
                rateLocationType: load.rateLocationType,
              }),
              isLiveLoad: true,
              locations: formStopsToLocationsDTO(locations),
              orderNumber: '',
              workflow: load.workflow,
              schedulePickup: load.schedulePickup,
              equipment: [
                {
                  accessorials: load.protectFromFreezing
                    ? [{ key: 'protect-from-freezing' }]
                    : [],
                  alternateTypes: [],
                  ...(declaredValue && { declaredValue }),
                  declaredValueCurrency: 'usd',
                  deliveryOption,
                  description: items[0].description?.trim() ?? '',
                  isHazardous: items.some(unitIsHazardous),
                  loadId: load.loadId,
                  mode: load.workflow,
                  type: load.workflow === 'truckload' ? 'ltl' : load.workflow, // TODO if we ever want truckload to use the same store
                  weight: sum(itemDtos.map(i => i.weight)),
                  weightUOM: 'lbs',
                  items: itemDtos,
                },
              ],
            }
          },
          handleLoadChange: e => {
            set(prev => {
              const { name } = e.target
              const load = { ...prev.load }

              if (name === 'rateLocationType') {
                load[name] = e.target.value as QuoteStopType
              }

              if (
                name === 'isFreightCollect' ||
                name === 'protectFromFreezing'
              ) {
                const checkbox = e.target as HTMLInputElement
                load[name] = checkbox.checked
              }

              if (name === 'schedulePickup') {
                const radio = e.target as HTMLInputElement
                load.schedulePickup = radio.value === 'true'
              }

              if (
                name === 'isFreightCollect' &&
                load.workflow === 'parcel' &&
                load.isFreightCollect
              ) {
                load.schedulePickup = false
              }

              return { load }
            })
          },
          handleSettingsChange: (
            setting: keyof QuoteFormSettings,
            value: boolean,
          ) => {
            set(prev => {
              return {
                settings: {
                  ...prev.settings,
                  [setting]: value,
                },
              }
            })

            if (!recentLoad) {
              toast.error(`You haven't created any shipments yet`, {
                id: 'recent-shipment',
              })
              return
            }

            if (
              setting === 'autoFillDestination' ||
              setting === 'autoFillOrigin'
            ) {
              const stopType: QuoteStopType =
                setting === 'autoFillDestination' ? 'destination' : 'origin'
              const shipmentLoc = recentLoad.locations.find(
                l => l.type === stopType,
              )
              if (value) {
                get().actions.setLocationData(stopType, {
                  ...(shipmentLoc && locationToFormStop(shipmentLoc, workflow)),
                  stopDate: defaultStopDate(stopType),
                  refNum: '',
                })
              } else {
                get().actions.setLocationData(
                  stopType,
                  stopType === 'origin'
                    ? defaultOrigin()
                    : defaultDestination(),
                )
              }
            }

            if (setting === 'autoFillProduct') {
              if (value) {
                get().itemActions.setItems(
                  recentLoad.items.map(i =>
                    toLoadHandlingUnitFormModel(
                      i,
                      props.recentUnits?.find(u => u._id === i.handlingUnitId),
                    ),
                  ),
                )
              } else {
                get().itemActions.resetItems()
              }
            }
          },
          setLocationData: (stopType, data) => {
            set(prev => {
              const { stopDate, refNums, ...location } = data
              const stopIx = stopType === 'origin' ? 0 : 1
              let nextStop = { ...prev.locations[stopIx] }
              setPathValues(nextStop, location)

              nextStop = sanitizeLocation(nextStop, workflow)

              if (stopDate || stopDate === null) {
                setPathValue(nextStop, 'stopDate', stopDate)
              }

              const load = { ...prev.load }
              const locations = prev.locations.map((x, ix) =>
                ix === stopIx ? nextStop : x,
              )

              return { load, locations }
            })
          },
          setFormRef: ref => {
            set({ routeFormRef: ref })
          },
          updateDeliveryOption(deliveryOption) {
            set(() => ({
              deliveryOption,
            }))
          },
          validateForm: () => {
            const formEl = get().routeFormRef
            const isValid = formEl?.checkValidity() ?? false

            if (!isValid) submitForm(formEl)
            return isValid
          },
        },
      }
    },
  )
}

type QuotePageStore = ReturnType<typeof createQuotePageStore>
type Params = { loadId?: string; mode?: QuoteRequestMode }
type Props = PropsWithChildren<{ workflow: 'ltl' | 'parcel' }>
const QuotePageContext = createContext<QuotePageStore | null>(null)

export const QuotePageProvider = ({ children, workflow }: Props) => {
  const { loadId, mode } = useParams<Params>()
  const { search } = useLocation()
  const loadQuery = useLoad(loadId)
  const handlingUnitsQuery = useHandlingUnits(loadQuery.data)
  const prefs = useAppPreferences()
  const recentLoadQuery = useRecentShipment(workflow)
  const recentLoad = recentLoadQuery.data
  const recentUnitsQuery = useHandlingUnits(recentLoad)
  const storeRef = useRef<QuotePageStore>()

  if (
    loadQuery.isError ||
    (loadQuery.isLoading && loadQuery.isFetching) ||
    handlingUnitsQuery.loading ||
    recentLoadQuery.isLoading ||
    recentUnitsQuery.loading
  ) {
    return (
      <>
        <div className="ltl-quote-route-ctn b1200:pb-28">
          <InfoBox icon={loadQuery.isError ? 'exclamation-triangle' : 'sync'}>
            {loadQuery.isError ? 'Shipment not found.' : 'Loading quote...'}
          </InfoBox>

          {loadQuery.isError && (
            <Navigate to={routes.quote(workflow)} replace />
          )}
        </div>
      </>
    )
  }

  if (!storeRef.current) {
    const queryString = new URLSearchParams(search)
    storeRef.current = createQuotePageStore({
      handlingUnits: handlingUnitsQuery.data,
      load: loadQuery.data,
      workflow,
      mode,
      recentLoad,
      recentUnits: recentUnitsQuery.data,
      settings: {
        autoFillDestination: !!prefs[`${workflow}_quote_remember_destination`],
        autoFillOrigin: !!prefs[`${workflow}_quote_remember_origin`],
        autoFillProduct: !!prefs[`${workflow}_quote_remember_product`],
      },
      createFreightCollect: !!queryString.get('cr'),
    })
  }

  return (
    <QuotePageContext.Provider value={storeRef.current}>
      {children}
    </QuotePageContext.Provider>
  )
}

export function useQuotePageStore() {
  const store = useContext(QuotePageContext)

  if (!store) {
    throw new Error('Missing QuotePageContext.Provider in the tree')
  }
  return useStore(store)
}

export function useQuotePageCtx<T>(
  selector: (state: QuotePageActions & QuotePageState & LoadItemsStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(QuotePageContext)

  if (!store) {
    throw new Error('Missing QuotePageContext.Provider in the tree')
  }
  return useStore(store, selector, equalityFn)
}

export const useQuotePageWorkflow = () =>
  useQuotePageCtx(ctx => ctx.load.workflow)
