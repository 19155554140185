import { type PropsWithChildren } from 'react'

import { FvButton } from '@fv/client-components'

import { useRedirectToStripePortal } from './hooks'

export const StripeCustomerPortalButton = ({ children }: PropsWithChildren) => {
  const { redirect, loading } = useRedirectToStripePortal()
  return (
    <FvButton loading={loading} theme="primary" onClick={redirect}>
      {children}
    </FvButton>
  )
}
